<template>
  <v-container fluid class="smy_wrapper">
    <v-container fluid class="smy_header">
      <clinicSelector />
    </v-container>
    <v-container fluid class="smy_contain">
      <v-row dense no-gutters class="table-header">
        <v-col xs=12 sm=12 md=6 lg=6 class="pb-0 mb-0 mt-0 pt-0" cols="6">
          <h2>{{ card_title }}</h2>
        </v-col>
        <v-col xs=12 sm=12 md=6 lg=6 class="pb-0 mb-0 mt-0 pt-0 d-flex justify-end" cols="6">
          <div style="width: 300px; display:inline-block">
            <v-text-field
              v-model="search"
              append-icon="$riwIconSet_search"
              label="Search"
              single-line
              hide-details
              clearable
              outlined
              dense
            ></v-text-field>
          </div>
          <v-btn class="ml-4" depressed outlined color="primary" @click="inviteNewObject">{{ createNewBtnTitle }}</v-btn>
        </v-col>
      </v-row>
      <v-row dense no-gutters>
        <v-col class="pb-0 mb-0 mt-0 pt-0" cols="12">
          <v-card class="flat">
          <v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :loading="loadingTable"
          >
            <template v-slot:top>
              <v-dialog v-model="edit_dialog" max-width="500px" scrollable persistent>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ editTitle }}</span>
                  </v-card-title>

                  <v-card-text style="height: 100%; overflow-y: auto;">
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field v-if="$store.getters.isSysAdmin" v-model="editedItem.clinicId" label="Clinic Id" disabled outlined></v-text-field>
                          <v-form v-model="edit_object_valid">
                            <v-text-field v-model="editedItem.name" label="Clinic Name" outlined :rules="[rules.required, rules.minLength]"></v-text-field>
                          </v-form>
                        </v-col>
                      </v-row>
                      
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="close">Cancel</v-btn>
                    <v-btn color="green accent-3" text @click="save" :disabled="!edit_object_valid">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class='ml-2'
                title="Edit"
                @click="showInfo(item)"
              >
                $riwIconSet_info
              </v-icon>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              {{ item.name || item.clinicConnectorId || 'Unknown; not yet connected.' }}
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-icon
                v-if="item.status === 'Connected'"
                size=18
                class='mr-2 pb-1'
                color="green accent-3"
              >
                $riwIconSet_connectionUp
              </v-icon>
              <v-icon
                v-else
                size=18
                class='mr-2 pb-1'
                color="red accent-3"
              >
                $riwIconSet_connectionDown
              </v-icon>
              {{ item.status }}
            </template>
            <template v-slot:[`item.permissions`]="{ item }">
              {{ item.permissions.join(", ") }}
            </template>
          </v-data-table>
          <v-dialog v-model="new_dialog" persistent scrollable max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ newTitle }}</span>
              </v-card-title>

              <v-card-text style="height: 100%; overflow-y: auto;">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div class="text-center">
                        <v-btn :href="connectorHREF" color="primary">Download Installation package</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="mt-4">
                      Use the following code on installation:
                      <v-text-field id="keyCode" style="font-size:2.5em;" class="centered-input mt-4" v-model="newObject.code" :success-messages="keyCodeSuccessMessage" :error-messages="keyCodeErrorMessage" outlined readonly>
                        <template v-slot:append>
                          <v-btn class="mb-4" @click="copyKey">Copy</v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="mt-4">
                      <div class="text-center">
                        <div v-if="newObject.status==='Pending'">
                          <v-progress-circular indeterminate color="amber accent-3" size=80 width=8 />
                        </div>
                        <div v-else-if="newObject.status==='Connected'">
                          <v-icon
                            size=100
                            class='ml-2'
                            title="Success"
                            color="green accent-3"
                          >
                            $riwIconSet_connectionUp
                          </v-icon>
                        </div>
                        <div v-else>
                          <v-icon
                            size=100
                            class='ml-2'
                            title="Error"
                            color="red accent-3"
                          >
                            $riwIconSet_connectionDown
                          </v-icon>
                        </div>
                        <v-text-field v-if="newObject.status" :value="newObject.status" label="Status" disabled solo dense class="centered-input pl-12 pr-12 ml-12 mr-12 mt-4"></v-text-field>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeNew" :disabled="newObject.status==='Connected'">Cancel</v-btn>
                <v-btn color="green accent-3" text @click="closeNewUpdate" :disabled="!(newObject.status==='Connected')">Ok</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
            <v-dialog v-model="info_dialog" max-width="500px" scrollable persistent>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ infoTitle }}</span>
                </v-card-title>

                <v-card-text style="height: 100%; overflow-y: auto;">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field v-if="$store.getters.isSysAdmin && this.infoItem.name" v-model="infoItem.clinicConnectorId" label="Connector Id" readonly outlined dense class="ml-4 mr-4 pt-0"></v-text-field>
                        <v-text-field :value="this.infoItem.name || this.infoItem.clinicConnectorId || 'Unknown; not yet connected.'" label="Connector Name/ID" readonly outlined dense rows=1 class="ml-4 mr-4"></v-text-field>
                        <v-text-field v-if="this.infoItem.ipaddress" :value="this.infoItem.ipaddress" label="IP Address" readonly outlined dense rows=1 class="ml-4 mr-4"></v-text-field>
                        <v-text-field v-if="this.infoItem.clientversion" :value="this.infoItem.clientversion" label="Client Version" readonly outlined dense rows=1 class="ml-4 mr-4"></v-text-field>
                        
                        <v-text-field v-if="this.infoItem.lastReceivedTime" :value="this.infoItem.lastReceivedTime" label="Last Communication Time (UTC)" readonly outlined dense rows=1 class="ml-4 mr-4"></v-text-field>
                        <v-text-field v-if="this.infoItem.code && $store.getters.isSysAdmin" :value="this.infoItem.code" label="Connector Install Code" readonly outlined dense rows=1 class="ml-4 mr-4"></v-text-field>
                        <div class="text-center">
                          <div v-if="this.infoItem.status==='Pending'">
                            <v-progress-circular indeterminate color="amber accent-3" size=80 width=8 />
                          </div>
                          <div v-else-if="this.infoItem.status==='Connected'">
                            <v-icon
                              size=100
                              class='ml-2'
                              title="Success"
                              color="green accent-3"
                            >
                              $riwIconSet_connectionUp
                            </v-icon>
                          </div>
                          <div v-else>
                            <v-icon
                              size=100
                              class='ml-2'
                              title="Success"
                              color="red accent-3"
                            >
                              $riwIconSet_connectionDown
                            </v-icon>
                          </div>
                          <v-text-field v-if="this.infoItem.status" :value="this.infoItem.status" label="Status" disabled solo dense class="centered-input pl-12 pr-12 ml-12 mr-12 mt-4"></v-text-field>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeInfo">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>

import adminConnectorsViewModel from '@/view-models/admin-connectors-view-model';

export default adminConnectorsViewModel;

</script>
